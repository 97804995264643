import React, { Component } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import logo from '../../assets/images/logo-ptp.png'
import logoMobile from '../../assets/images/logo_ptp-mobile.png'
import logoMobile2 from '../../assets/images/logo-tau-ai-te-ira.png'
import { SearchBar } from './search'
import { SearchHelper } from '../../helpers'
import { SECTIONS } from '../../utils/url'

export default class PTPHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            isSearchActive: false,
        }
    }

    openSearch = () => this.setState({ isSearchActive: true })
    closeSearch = () => this.setState({ isSearchActive: false })

    componentDidMount() {
        SearchHelper.saveUTMParams(window.location.search)
    }

    render() {
        const { openClass, activeSection, inferSearchSection } = this.props
        const { showMenu, isSearchActive } = this.state

        // Get current section name...
        const SECTIONS_ARRAY = Object.values(SECTIONS)
        const section = SECTIONS_ARRAY.find((section) => section.key === activeSection)
        const sectionName = section && section.name
        const splitName = (name) => {
            return name?.split('<br>').map((part, index) => (
                <React.Fragment key={index}>
                    {part}
                    {index !== name.split('<br>').length - 1 && <br />}
                </React.Fragment>
            ))
        }

        return (
            <header className={classNames('app-header', { [openClass]: openClass })}>
                <div className="heading">
                    <Link to="/power-to-protect" id="logo">
                        <img src={logo} alt="power to protect Logo" title="power to protect Logo" className="logo-icon" />
                        <img src={logoMobile} alt="power to protect Logo" title="power to protect Logo" className="logo-icon-mobile" />
                    </Link>
                    <div id="logo2">
                        <img src={logoMobile2} alt="Tau AI TE IRA Logo" title="Tau AI TE IRA" className="logo-tau-ai-te-ira" />
                    </div>


                    <div id="header-searchbar-wrapper" className={isSearchActive ? 'active' : ''}>
                        <SearchBar id={'header-searchbar'} activeSection={inferSearchSection && activeSection} isPowerToProtect openSearch={() => this.openSearch()} closeSearch={() => this.closeSearch()} />
                    </div>
                </div>
                <div className={'navbar-wrapper'}>
                    <div className={'navbar'}>
                        <div className="nav-active-menu-item" onClick={() => this.toggleMenu()}>
                            {splitName(sectionName)} <i className="far fa-angle-down" />
                        </div>
                        <ul className={`nav-menu ${showMenu ? 'active' : ''}`}>
                            <li className={classNames({ highlight: activeSection === SECTIONS.WHANAU_MAORI.key })}>
                                <Link to={SECTIONS.WHANAU_MAORI.url}> {splitName(SECTIONS.WHANAU_MAORI.name)}</Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.HEALTH_SERVICES.key })}>
                                <Link to={SECTIONS.HEALTH_SERVICES.url}>{splitName(SECTIONS.HEALTH_SERVICES.name)} </Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.PACIFIC_VILLAGE.key })}>
                                <Link to={SECTIONS.PACIFIC_VILLAGE.url}>{SECTIONS.PACIFIC_VILLAGE.name}</Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.HEALTH_PROFESSIONALS_PTP.key })}>
                                <Link to={SECTIONS.HEALTH_PROFESSIONALS_PTP.url}> {splitName(SECTIONS.HEALTH_PROFESSIONALS_PTP.name)}</Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.ABOUT_US.key })}>
                                <Link to={SECTIONS.ABOUT_US.url}> {splitName(SECTIONS.ABOUT_US.name)}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        )
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu,
        })
    }
}

PTPHeader.defaultProps = {
    inferSearchSection: true,
}
