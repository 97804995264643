import React from "react";
import browserslist from "browserslist";
import { matchesUA } from "browserslist-useragent";

const UnsupportedBrowserMessage = () => {
  const isBrowserSupported = () => {
    if (typeof window === "undefined" || typeof navigator === "undefined") {
      // During server-side rendering, just assume supported
      return true;
    }

    const browserslistConfig = browserslist();
    const userAgent = navigator.userAgent;
    return matchesUA(userAgent, {
      browsers: browserslistConfig,
      allowHigherVersions: true,
    });
  };

  if (!isBrowserSupported()) {
    return (
      <div style={overlayStyles}>
        <div style={modalStyles}>
          <h2>Browser Not Supported</h2>
          <p>
            It looks like you're using an outdated browser. To view this site 
            properly, please switch to a more modern browser such as<span> </span> 
            <a href="https://www.google.com/chrome/">Chrome</a>, 
            <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>, 
            or <a href="https://www.microsoft.com/edge">Edge</a>.
          </p>
        </div>
      </div>
    );
  }

  return null;
};

// Example inline styles
const overlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

const modalStyles = {
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "400px",
  textAlign: "center",
};

export default UnsupportedBrowserMessage;
