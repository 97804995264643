import React, { PureComponent } from 'react'
import { Header, Footer, Overlay, PTPHeader } from '../common/index'
import '../../assets/styles/app.scss'
import { addEmbedScript, addHeadScript } from '../../utils/import-embed-script'
import { defaultFacebookPixel, safekidsFacebookPixel } from '../common/FacekookPixel'
import start from '../../assets/images/start.svg'
import arrowUp from '../../assets/images/arrow-up.svg'
import { Link } from 'gatsby'

const gtagSafekidsScript = `gtag('event', 'conversion', {'send_to': 'AW-968231252/EHTZCO_F1tsZENSS2M0D'});`;

export default class PageLayout extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            showScroll: false,
        }
    }

    componentDidMount() {
        addEmbedScript()
        addHeadScript(defaultFacebookPixel)
        if (this.props.activeSection === 'Safekids') {
            addHeadScript(safekidsFacebookPixel)
            addHeadScript(gtagSafekidsScript)
        }

        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        if (window.scrollY > 300) {
            this.setState({ showScroll: true })
        } else {
            this.setState({ showScroll: false })
        }
    }

    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    render() {
        const className = this.props.className || 'generic-page'
        const isPowerToProtect = className.includes('ptp-root')
        return (
            <div className={`app-root ${className} ${this.props.isReading ? 'is-reading' : ''}`}>
                {isPowerToProtect ? <PTPHeader openClass={this.props.openClass} activeSection={this.props.activeSection} inferSearchSection={this.props.inferSearchSection} /> : <Header openClass={this.props.openClass} activeSection={this.props.activeSection} inferSearchSection={this.props.inferSearchSection} />}
                {this.props.children}
                <Footer activeSection={this.props.activeSection} hideSocials={this.props.hideSocials} sitemap={this.props.sitemap} isPowerToProtect={isPowerToProtect} />
                <Overlay />

                {isPowerToProtect && (
                    <div className="floating-button">
                        <Link to="/" className="go-to-starship">
                            <img src={start} alt="starship" />
                            <span>Back to Starship </span>
                        </Link>
                        {this.state.showScroll && (
                            <button className="scroll-to-top" onClick={this.scrollToTop}>
                                <img src={arrowUp} alt="Go to top" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        )
    }
}
