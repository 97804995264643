/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import ScrollProvider from './src/contexts/ScrollContext'
import GuidelineProvider from './src/contexts/GuidelineContext'
import PopupProvider from './src/contexts/PopupContext'
import OverlayProvider from './src/contexts/OverlayContext'
import HealthPointServiceProvider from './src/contexts/HealthPointServiceContext'
import UnsupportedBrowserMessage from './src/components/common/UnsupportedBrowserMessage'
// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import 'formdata-polyfill'

export const wrapRootElement = ({ element }) => {
    return (
        <OverlayProvider>
            <PopupProvider>
                <GuidelineProvider>
                    <HealthPointServiceProvider>
                        <ScrollProvider>
                            <UnsupportedBrowserMessage />
                            {element}
                        </ScrollProvider>
                    </HealthPointServiceProvider>
                </GuidelineProvider>
            </PopupProvider>
        </OverlayProvider>
    )
}

export const onClientEntry = () => {
    // Ensure gtag is available
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
    }
};
